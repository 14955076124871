"use strict";

import { createRouter, createWebHistory } from "vue-router";

const routes = [];

routes.push({
    "path": "",
    "redirect": {
        "name": "dashboard",
    },
});

routes.push({
    "path": "",
    "component": () => import("./layouts/AuthenticationFrame.vue"),
    "children": [
        {
            "path": "login",
            "name": "login",
            "component": () => import("./views/LogIn.vue"),
            "meta": {
                "requiresAuth": false,
            },
        },
    ],
});

routes.push({
    "path": "",
    "component": () => import("./layouts/DashboardFrame.vue"),
    "children": [
        {
            "path": "dashboard",
            "name": "dashboard",
            "component": () => import("./views/CreatorDashboard"),
            "meta": {
                "requiresAuth": true,
            },
        },
        {
            "path": "content",
            "name": "content",
            "redirect": {
                "name": "contentPackage",
            },
            "children": [
                {
                    "path": "package",
                    "children": [
                        {
                            "path": "",
                            "name": "contentPackage",
                            "component": () => import("./views/CreatorContentPackage"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "create",
                            "name": "contentPackageCreate",
                            "component": () => import("./views/CreatorContentPackageCreate"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "uitemplate/:id",
                            "name": "uiTemplate",
                            "component": () => import("./views/CreatorUiTemplateCreate"),
                            "props": routes => ({
                                "id": routes.params.id,
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": ":id/content",
                            "children": [
                                {
                                    "path": "",
                                    "name": "addContent",
                                    "component": () => import("./views/CreatorContentPackageCreate"),
                                    "props": routes => ({
                                        "id": routes.params.id,
                                    }),
                                    "meta": {
                                        "requiresAuth": true,
                                    },
                                },
                                {
                                    "path": "update",
                                    "name": "editContent",
                                    "component": () => import("./views/CreatorContentPackageCreate"),
                                    "props": routes => ({
                                        "id": routes.params.id,
                                    }),
                                    "meta": {
                                        "requiresAuth": true,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    "path": "storage",
                    "children": [
                        {
                            "path": "",
                            "name": "contentStorage",
                            "component": () => import("./views/CreatorContentStorage"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "create",
                            "name": "contentStorageCreate",
                            "component": () => import("./views/CreatorContentStorageCreate"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            "path": "device",
            "children": [
                {
                    "path": "",
                    "name": "device",
                    "component": () => import("./views/CreatorDevice"),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "detail/:id",
                    "name": "deviceDetail",
                    "component": () => import("./views/CreatorDeviceDetail"),
                    "props": routes => ({
                        "id": routes.params.id,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "content/distribution",
            "children": [
                {
                    "path": "",
                    "name": "contentDistribution",
                    "component": () => import("./views/CreatorContentDistribution"),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "detail/:id",
                    "name": "contentDistributionDetail",
                    "component": () => import("./views/CreatorContentDistributionDetail"),
                    "props": routes => ({
                        "id": routes.params.id,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "analytics",
            "name": "analytics",
            "component": () => import("./views/CreatorAnalytics"),
            "meta": {
                "requiresAuth": true,
            },
        },
        {
            "path": "account",
            "name": "account",
            "redirect": {
                "name": "accountDetail",
            },
            "children": [
                {
                    "path": "detail",
                    "name": "accountDetail",
                    "component": () => import("./views/CreatorAccount"),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "license",
                    "children": [
                        {
                            "path": "",
                            "name": "accountLicense",
                            "component": () => import("./views/CreatorAccountLicense"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "update",
                            "name": "accountLicenseUpdate",
                            "component": () => import("./views/CreatorAccountLicenseUpdate"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "cancel",
                            "name": "accountLicenseCancel",
                            "component": () => import("./views/CreatorAccountLicenseCancel"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
                {
                    "path": "user",
                    "children": [
                        {
                            "path": "",
                            "name": "accountUser",
                            "component": () => import("./views/CreatorAccountUser"),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "create",
                            "children": [
                                {
                                    "path": "email",
                                    "name": "accountUserCreateEmail",
                                    "component": () => import("./views/CreatorAccountUserCreateEmail"),
                                    "meta": {
                                        "requiresAuth": true,
                                    },
                                },
                                {
                                    "path": "input",
                                    "name": "accountUserCreateInput",
                                    "component": () => import("./views/CreatorAccountUserCreateInput"),
                                    "meta": {
                                        "requiresAuth": true,
                                    },
                                },
                            ],
                        },
                        {
                            "path": "update",
                            "children": [
                                {
                                    "path": ":id/input",
                                    "name": "accountUserUpdateInput",
                                    "component": () => import("./views/CreatorAccountUserUpdateInput"),
                                    "props": routes => ({
                                        "id": routes.params.id,
                                    }),
                                    "meta": {
                                        "requiresAuth": true,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            "path": "profile",
            "name": "profile",
            "component": () => import("./views/ProfileUpdate"),
            "meta": {
                "requiresAuth": true,
            },
        },
    ],
});

routes.push({
    "path": "",
    "component": () => import("./layouts/AccountCreate.vue"),
    "children": [
        {
            "path": "account",
            "children": [
                {
                    "path": "create",
                    "children": [
                        {
                            "path": "email",
                            "children": [
                                {
                                    "path": "",
                                    "name": "accountCreateEmail",
                                    "component": () => import("./views/AccountCreateEmail"),
                                    "meta": {
                                        "requiresAuth": false,
                                    },
                                },
                                {
                                    "path": "confirm",
                                    "name": "accountCreateEmailConfirm",
                                    "component": () => import("./views/AccountCreateEmailConfirm"),
                                    "meta": {
                                        "requiresAuth": false,
                                    },
                                },
                            ],
                        },
                        {
                            "path": "input",
                            "name": "accountCreateInput",
                            "component": () => import("./views/AccountCreateInput"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                        {
                            "path": "plan",
                            "name": "accountCreatePlan",
                            "component": () => import("./views/AccountCreatePlan"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                        {
                            "path": "complete",
                            "name": "accountCreateComplete",
                            "component": () => import("./views/AccountCreateComplete"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                    ],
                },
            ],
        },
    ],
});

routes.push({
    "path": "",
    "component": () => import("./layouts/ContractsFrame.vue"),
    "children": [
        {
            "path": "account",
            "children": [
                {
                    "path": "terms_of_service",
                    "name": "accountTermsOfService",
                    "component": () => import("./views/AccountTermsOfService"),
                    "meta": {
                        "requiresAuth": false,
                    },
                },
                {
                    "path": "privacy_policy",
                    "name": "accountPrivacyPolicy",
                    "component": () => import("./views/AccountPrivacyPolicy"),
                    "meta": {
                        "requiresAuth": false,
                    },
                },
                {
                    "path": "faq",
                    "name": "accountFAQ",
                    "component": () => import("./views/AccountFAQ"),
                    "meta": {
                        "requiresAuth": false,
                    },
                },
            ],
        },
    ],
});

routes.push({
    "path": "",
    "component": () => import("./layouts/PasswordReset.vue"),
    "children": [
        {
            "path": "password",
            "children": [
                {
                    "path": "reset",
                    "children": [
                        {
                            "path": "",
                            "name": "passwordReset",
                            "component": () => import("./views/PasswordReset"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                        {
                            "path": "confirm",
                            "name": "passwordResetConfirm",
                            "component": () => import("./views/PasswordResetConfirm"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                        {
                            "path": "input/:uid/:token",
                            "name": "passwordResetInput",
                            "component": () => import("./views/PasswordResetInput"),
                            "meta": {
                                "requiresAuth": false,
                            },
                            "props": routes => ({
                                "uid": routes.params.uid,
                                "token": routes.params.token,
                            }),
                        },
                        {
                            "path": "complete",
                            "name": "passwordResetComplete",
                            "component": () => import("./views/PasswordResetComplete"),
                            "meta": {
                                "requiresAuth": false,
                            },
                        },
                    ],
                },
            ],
        },
    ],
});

// 存在しないページはrootへリダイレクト
routes.push({
    "path": "/:pathMatch(.*)",
    "redirect": {
        "name": "dashboard",
    },
});

// new router
const router = createRouter({
    "history": createWebHistory(process.env.BASE_URL),
    "routes": routes,
    scrollBehavior(to) {
        if (!to.hash) {
            return {
                "top": 0,
                "left": 0,
            };
        }
    },
});

// auth check
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        next();
    }
    else {
        next();
    }
});

export default router;
